import { useEffect, useState } from "react";
import BookingGraph from "./booking-graph"
import { Box, Chip } from "@mui/material";
import KPICard from "../../../components/kpi-card";
import { getFirebaseFirestore } from "../../../const";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useSelector } from "react-redux";
import { AppState } from "../../../types";
import moment from "moment";

const Insights = () => {
    const [selectedPeriod, setSelectedPeriod] = useState<"Week" | "Month" | "Year">("Week");
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState<{ labels: string[]; data: number[] }>({ labels: [], data: [] });
    const [clientCount, setClientCount] = useState(0);
    const sereneUser = useSelector((state: AppState) => state.sereneUser);
    const title = `New clients this ${selectedPeriod.toLowerCase()}`

    useEffect(() => {
        if (sereneUser.fetched) {
            getClientsCountForPeriod(sereneUser.id, selectedPeriod).then((n) => {
                setClientCount(n)
            })
        }
        setLoading(true);
        setTimeout(() => {
            fetchBookings();
        }, 1000);
    }, [selectedPeriod])


    const fetchBookings = async () => {
        const now = new Date();
        let startTime: Date;
        let labels: string[] = [];

        // Determine the start time and labels based on the selected period
        if (selectedPeriod === "Week") {
            // Find the Monday of the current week
            const now = new Date();
            const dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)
            const daysToMonday = (dayOfWeek === 0 ? -6 : 1 - dayOfWeek); // Offset to get to Monday
            const monday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + daysToMonday);

            startTime = new Date(monday); // Set start time to Monday
            labels = Array.from({ length: 7 }, (_, i) => {
                const date = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + i);
                return date.toLocaleString("default", { weekday: "short" }); // Show weekday names
            });
        } else if (selectedPeriod === "Month") {
            startTime = new Date(now.getFullYear(), now.getMonth(), 1);
            const daysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
            labels = Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString());
        } else {
            startTime = new Date(now.getFullYear(), 0, 1);
            labels = Array.from({ length: 12 }, (_, i) =>
                new Date(now.getFullYear(), i, 1).toLocaleString("default", { month: "short" })
            );
        }

        // Query Firestore for bookings within the selected period
        const bookingsQuery = query(
            collection(getFirebaseFirestore(), "booking"),
            where("timestamp", ">=", moment(startTime).unix() * 1000),
            where("user", "==", sereneUser.id)
        );

        const snapshot = await getDocs(bookingsQuery);
        const bookings = snapshot.docs.map((doc) => doc.data());

        // Aggregate bookings into a count based on the labels
        const counts = labels.map((label, index) => {
            if (selectedPeriod === "Week") {
                return bookings.filter((booking) => {
                    const bookingDate = moment(booking.timestamp).toDate();
                    return bookingDate.toLocaleString("default", { weekday: "long" }) === label;
                }).length;
            } else if (selectedPeriod === "Month") {
                return bookings.filter((booking) => {
                    const bookingDate = moment(booking.timestamp).toDate();
                    return bookingDate.getDate() === index + 1;
                }).length;
            } else {
                return bookings.filter((booking) => {
                    const bookingDate = moment(booking.timestamp).toDate();
                    return bookingDate.getMonth() === index;
                }).length;
            }
        });

        setChartData({ labels, data: counts });
        setLoading(false);
    };


    return <div>
        <Box display={"flex"} gap={"10px"} mt={"20px"}>
            {["Week", "Month", "Year"].map((d: any) => <Chip label={d} onClick={() => setSelectedPeriod(d)}
                variant={selectedPeriod == d ? "filled" : "outlined"}
                disabled={loading}
                sx={{
                    backgroundColor: selectedPeriod == d ? "black" : "default",
                    color: selectedPeriod == d ? "white" : "default",
                    paddingLeft: '10px',
                    paddingRight: "10px"
                }} />)}
        </Box>
        <br />
        <Box ml={"10px"} display={"flex"} flexWrap={"wrap"} alignItems={"center"} gap={"25px"}>
            <BookingGraph selectedPeriod={selectedPeriod} loading={loading} chartData={chartData} />
            <KPICard number={clientCount} title={title} />
        </Box>
    </div>
}


const getClientsCountForPeriod = async (userId: string, period: string) => {
    const db = getFirebaseFirestore();
    const now = new Date();
    let startTime;

    // Determine the startTime based on the given period
    switch (period) {
        case "Week":
            startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1); // Start of the current week (Monday)
            break;
        case "Month":
            startTime = new Date(now.getFullYear(), now.getMonth(), 1); // Start of the current month
            break;
        case "Year":
            startTime = new Date(now.getFullYear(), 0, 1); // Start of the current year
            break;
        default:
            throw new Error("Invalid period. Valid options are 'week', 'month', or 'year'.");
    }

    // Convert the startTime to a Firestore-compatible timestamp
    const startTimestamp = startTime.getTime();

    try {
        // Reference to the user's client records collection
        const clientRecordsRef = collection(db, `serene_users/${userId}/client_records`);
        // Query to filter documents based on the creation_timestamp
        const clientsQuery = query(
            clientRecordsRef,
            where("creation_timestamp", ">=", startTimestamp)
        );
        // Fetch the documents matching the query
        const querySnapshot = await getDocs(clientsQuery);

        // Return the count of matched documents
        return querySnapshot.size;
    } catch (error) {
        console.error("Error fetching client records: ", error);
        throw error;
    }
};

export default Insights;