import { DashboardCard, ShimmerCard } from "../dashboard-card";
import notesImg from "../../../assets/note_book.png";
import calenderImg from "../../../assets/calender.png";
import profileImg from "../../../assets/profile.png";
import voiceRecorder from "../../../assets/voice-recorder.svg";
import homeStyle from "../style/home.module.css";
import { Box, CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { BookingData } from "../../../types";
import { useCallback } from "react";
import { formatTimeDifference } from "../../../helper/time-helper";
import Insights from "./insights";
import KPICard from "../../../components/kpi-card";

export const Home = ({
    onViewNotes,
    onShowCalender,
    notesLoading,
    lastNote,
    pendingLoading,
    actionItems,
    inProgress,
    calenderConnected,
    upcomingEvents,
    calenderLoading,
    userFetched,
    profileCompleted,
    sereneActive
}: {
    onViewNotes: any;
    onShowCalender: any;
    notesLoading: boolean;
    lastNote: any;
    pendingLoading: boolean;
    actionItems: number;
    inProgress: number;
    calenderConnected: boolean;
    upcomingEvents: BookingData[];
    calenderLoading: boolean;
    userFetched: boolean;
    profileCompleted: boolean,
    sereneActive: boolean
}) => {
    const navigate = useNavigate();
    const navigationCallback = useCallback(() => { navigate("#calender") }, [])

    let subtitle = "";

    if (!notesLoading && lastNote != null) {
        const noteTimestamp = lastNote.timestamp;
        const time = moment(noteTimestamp).format("MMM Do");
        subtitle = `Last note generated on ${time}`;
    } else {
        subtitle = "No notes generated yet";
    }

    return (
        <div className={homeStyle.container}>
            <Typography variant="h4">At A Glance</Typography>
            <Typography variant="body2" color={"grey"} fontSize={14}>
                Your priority list, simplified
            </Typography>
            <br />
            <div className={homeStyle.row_1}>
                {!userFetched ? (
                    // Show shimmer cards when userFetched is false
                    <>
                        <ShimmerCard />
                        <ShimmerCard />
                    </>
                ) : (
                    <>
                        {profileCompleted ? <>
                            {userFetched && !calenderConnected && (
                                <DashboardCard
                                    title="Sessions"
                                    subtitle={"Set up scheduling"}
                                    image={calenderImg}
                                    actionTitle={"Start"}
                                    onActionClick={() => {
                                        navigate("/setup-scheduling");
                                    }}
                                    loading={false}
                                >
                                    <div className={homeStyle.inner_container}>
                                        <div>
                                            <Typography variant="body2" fontSize={12}>
                                                Follow the guide to set up your own scheduling page
                                            </Typography>
                                        </div>
                                    </div>
                                </DashboardCard>
                            )}
                            {!sereneActive ? <DashboardCard
                                title="Copilot"
                                subtitle={"Set up your personal assistant"}
                                image={voiceRecorder}
                                actionTitle={"Install"}
                                onActionClick={() => {

                                    window.location.href = "https://chromewebstore.google.com/detail/serene-ai-notetaker-for-m/nibecbnhnciangjglhlfeecnodoedpdk?authuser=0&hl=en"
                                }}
                                loading={false}
                            >
                                <div className={homeStyle.inner_container}>
                                    <Typography variant="body2" fontSize={12}>
                                        {" "}
                                        Your boost in productivity begins here
                                    </Typography>
                                </div>
                            </DashboardCard> : null}
                            <DashboardCard
                                title="My Notes"
                                subtitle={subtitle}
                                image={notesImg}
                                actionTitle={"View"}
                                onActionClick={onViewNotes}
                                loading={notesLoading}
                            >
                                <div className={homeStyle.inner_container}>
                                    {notesLoading || pendingLoading ? (
                                        <div className={homeStyle.loading_container}>
                                            <CircularProgress size={20} />
                                        </div>
                                    ) : (
                                        <div>
                                            <Typography variant="body2" fontSize={12}>
                                                {actionItems == 0
                                                    ? "👍 You are all caught up!"
                                                    : `✒️ ${actionItems} action ${actionItems == 1
                                                        ? "item"
                                                        : "items"
                                                    } pending`}
                                            </Typography>
                                            {/* <Typography variant="body2" fontSize={12}>
                                                {`⚙️ ${inProgress} ${inProgress == 1
                                                    ? "transcription"
                                                    : "transcriptions"
                                                    } in progress`}
                                            </Typography> */}
                                        </div>
                                    )}
                                </div>
                            </DashboardCard>
                            {userFetched && calenderConnected && (
                                <DashboardCard
                                    title="Sessions"
                                    subtitle={"Upcoming sessions"}
                                    image={calenderImg}
                                    onActionClick={navigationCallback}
                                    actionTitle={"Manage"}
                                    loading={false}
                                >
                                    <div className={homeStyle.inner_container}>
                                        <Typography variant="body2" fontSize={12}>
                                            {upcomingEvents.length == 0 ? "You do not have any scheduled session for the day" : <UpComingEvents event={upcomingEvents[0]} />}
                                        </Typography>

                                    </div>
                                </DashboardCard>
                            )}
                        </> : <DashboardCard
                            title="Complete Profile"
                            subtitle={"Let's set up your profile"}
                            image={profileImg}
                            actionTitle={"Start"}
                            onActionClick={() => {
                                navigate("/onboard");
                            }}
                            loading={!userFetched}
                        >
                            <div className={homeStyle.inner_container}>
                                <Typography variant="body2" fontSize={12}>
                                    {" "}
                                    We need a few details to get you started!
                                </Typography>
                            </div>
                        </DashboardCard>}
                    </>
                )}
            </div>
            <Typography variant="h4">Business Insights</Typography>
            <Typography variant="body2" color={"grey"} fontSize={14}>
                A comprehensive view of your practice's growth and activity over time
            </Typography>
            <Insights />
            <br />
            <br />
        </div>
    );
};

/**
 *
 * @param event
 * @returns
 */
const UpComingEvents = ({ event }: { event: BookingData }) => {
    const eventStart = event.start.dateTime;
    const difference = formatTimeDifference(eventStart);
    return (
        <div>
            <Typography variant="body2" fontSize={12}>
                🕐 You have a session coming up in <strong>{difference}</strong>
            </Typography>
        </div>
    );
};
