import { useEffect, useState } from "react";
import { AppState, Birp, NoteDetails, NoteType, Sentiment, Soap } from "../../types";
import {
    Alert, Breadcrumbs, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel,
    Link, TextField, Tooltip, Typography
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import noteDetailsStyle from "./style/note-edit.module.css"
import moment from "moment";
import { SoapNotes } from "./soap-note";
import { BirpNotes } from "./birp-note";
import Checkbox from '@mui/material/Checkbox';
import CancelIcon from '@mui/icons-material/Cancel';
import { Edit } from "../../components/edit";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { getFirebaseFirestore, SERENE_NOTES } from "../../const";
import React from "react";
import InfoIcon from '@mui/icons-material/Info';
import { ProtectedRoute } from "../../components/protected-route";
import BdiChart from "./bdi-chart";
import { TextInput } from "flowbite-react";
import SelectClient from "./select-client";


export const NoteEdit = ({ }) => {
    const [newName, setNewName] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [newBirpNote, setNewBirpNote] = useState<Birp>({ behavior: "", intervention: "", response: "", plan: "" });
    const [newSoapNote, setNewSoapNote] = useState<Soap>({ subjective: "", objective: "", assessment: "", plan: "" });
    const [loading, setLoading] = useState(true);
    const [note, setNote] = useState<NoteDetails | undefined>()
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogEditType, setDialogEditType] = useState<"name" | "email" | "soapNote" | "birpNote" | "">("");
    const [noteType, setNoteType] = useState<NoteType>("SOAP")
    const location = useLocation();
    let noteFromLocation: NoteDetails | undefined = location?.state?.note
    const params = useParams()
    const navigate = useNavigate()

    // update the local state on note change
    useEffect(() => {
        if (note) {
            setNewBirpNote(note?.serene_notes.birp_notes ?? { behavior: "", intervention: "", response: "", plan: "" })
            setNewSoapNote(note?.serene_notes.soap_report ?? { subjective: "", objective: "", assessment: "", plan: "" })
        }
    }, [note])


    useEffect(() => {
        if (!noteFromLocation) {
            setLoading(true)
        } else {
            setNote(noteFromLocation)
        }

        const unsubscribe = setUpNoteListener()

        return () => unsubscribe()
    }, [])

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/dashboard" >
            Dashboard
        </Link>,
        <Link
            underline="hover"
            key="2"
            color="inherit"
            href="/dashboard#notes"
        >
            Notes
        </Link>,
        <Typography key="3" sx={{ color: 'text.primary' }}>
            Edit
        </Typography>,
    ];


    /**
     * Set up the listener for the notes to listen for updates
     * @returns 
     */
    const setUpNoteListener = () => {

        const noteDocRef = doc(getFirebaseFirestore(), SERENE_NOTES, params["id"] ?? "");

        // Set up the listener for real-time updates
        const unsubscribe = onSnapshot(noteDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                setNote(docSnapshot.data() as NoteDetails)
                noteFromLocation = docSnapshot.data() as NoteDetails
            }
            setLoading(false)
        }, (error) => {
            console.log("Error fetching document: ", error);
        });

        return unsubscribe;
    }

    /**
     * Get the title of the dialog box.
     * @returns 
     */
    const getDialogTitle = () => {
        switch (dialogEditType) {
            case "name":
                return "Edit name"
            case "email":
                return "Add To History"
            case "birpNote":
                return "Edit BIRP"
            case "soapNote":
                return "Edit SOAP"
        }
    }

    /**
     * 
     */
    const toggleApprove = async () => {
        if ((note?.email ?? "").length == 0 && newEmail.length == 0) {
            alert("Please enter the email to save the note as part of the client records. Your client email is required to organize the notes")
            return;
        }
        const noteDocRef = doc(getFirebaseFirestore(), SERENE_NOTES, params["id"] ?? "");
        const prevNote = note!
        await updateDoc(noteDocRef, {
            pending: !prevNote.pending
        });
    }

    /**
     * 
     */
    const saveNote = async () => {
        const noteDocRef = doc(getFirebaseFirestore(), SERENE_NOTES, params["id"] ?? "");
        const prevNote = note!
        console.log(prevNote)
        switch (dialogEditType) {
            case "name":
                prevNote.name = newName
                break;
            case "birpNote":
                prevNote.serene_notes.birp_notes = newBirpNote
                break;
            case "soapNote":
                prevNote.serene_notes.soap_report = newSoapNote
                break;
            case "email":
                prevNote.email = newEmail
        }
        try {
            await updateDoc(noteDocRef, prevNote);
        } catch (error) {
            console.log("Error updating doc", error)
        }
    }



    /**
     * The dialog box body
     * @returns 
     */
    const getDialogBody = () => {
        switch (dialogEditType) {
            case "name":
                return (
                    <React.Fragment>
                        <br />
                        <TextInput
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                        <br />
                    </React.Fragment>
                );
            case "email":
                return <SelectClient />
            case "birpNote":
                return <React.Fragment>
                    <br />
                    <TextField label="Behaviour"
                        multiline
                        fullWidth
                        minRows={5}
                        value={newBirpNote.behavior}
                        onChange={(e) => { setNewBirpNote({ ...newBirpNote, behavior: e.target.value }) }} />
                    <TextField label="Intervention"
                        multiline
                        fullWidth
                        minRows={5}
                        value={newBirpNote.intervention}
                        onChange={(e) => { setNewBirpNote({ ...newBirpNote, intervention: e.target.value }) }} />
                    <TextField label="Response"
                        multiline
                        fullWidth
                        minRows={5}
                        value={newBirpNote.response}
                        onChange={(e) => { setNewBirpNote({ ...newBirpNote, response: e.target.value }) }} />
                    <TextField label="Plan"
                        multiline
                        fullWidth
                        minRows={5}
                        value={newBirpNote.plan}
                        onChange={(e) => { setNewBirpNote({ ...newBirpNote, plan: e.target.value }) }} />
                    <br />
                </React.Fragment>
            case "soapNote":
                return <React.Fragment>
                    <br />
                    <TextField label="Subjective"
                        multiline
                        fullWidth
                        minRows={5}
                        value={newSoapNote.subjective}
                        onChange={(e) => { setNewSoapNote({ ...newSoapNote, subjective: e.target.value }) }} />
                    <TextField label="Objective"
                        multiline
                        fullWidth
                        minRows={5}
                        value={newSoapNote.objective}
                        onChange={(e) => { setNewSoapNote({ ...newSoapNote, objective: e.target.value }) }} />
                    <TextField label="Assessment"
                        multiline
                        fullWidth
                        minRows={5}
                        value={newSoapNote.assessment}
                        onChange={(e) => { setNewSoapNote({ ...newSoapNote, assessment: e.target.value }) }} />
                    <TextField label="Plan"
                        multiline
                        fullWidth
                        minRows={5}
                        value={newSoapNote.plan}
                        onChange={(e) => { setNewSoapNote({ ...newSoapNote, plan: e.target.value }) }} />
                    <br />
                </React.Fragment>
        }
    };


    // calculate the sentiments
    // const { positivePercent, negativePercent, neutralPercent } = calculateSentiment(note?.serene_notes.sentiments);
    const bdi = Object.values(note?.bdi_score ?? { total: 0 }).reduce((p, c) => p + c, 0)
    let label;
    switch (true) {
        case (bdi >= 0 && bdi <= 9):
            label = 'Minimal depression';
            break;
        case (bdi >= 10 && bdi <= 18):
            label = 'Mild depression';
            break;
        case (bdi >= 19 && bdi <= 29):
            label = 'Moderate depression';
            break;
        case (bdi >= 30 && bdi <= 63):
            label = 'Severe depression';
            break;
        default:
            label = 'Invalid BDI score';
    }



    return (
        <ProtectedRoute>
            {loading ? <div className={noteDetailsStyle.container_loading}><CircularProgress /> </div> :
                <div className={noteDetailsStyle.container}>
                    <div>
                        <Dialog
                            fullWidth={true}
                            // maxWidth={true}
                            open={openDialog}
                            onClose={() => { setOpenDialog(false) }}
                        >
                            <DialogTitle>{getDialogTitle()}</DialogTitle>
                            <DialogContent sx={{
                                display: "flex",
                                padding: "20px",
                                flexDirection: "column",
                                gap: "10px"
                            }}>
                                {getDialogBody()}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => {
                                    saveNote()
                                    setOpenDialog(false)
                                }}>Save</Button>
                                <Button onClick={() => {
                                    setOpenDialog(false)
                                }}>Close</Button>
                            </DialogActions>
                        </Dialog>
                        <div className={noteDetailsStyle.row}>
                            <Breadcrumbs separator="›" aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                            <div className={noteDetailsStyle.row_spacer} />
                            <div onClick={() => navigate(-1)}>
                                <CancelIcon fontSize="large" sx={{ cursor: "pointer", fontSize: "45px" }} />
                            </div>
                            <br />
                        </div>
                        <br />
                        <Alert severity={note?.pending ? "info" : "success"} sx={{ borderRadius: "10px" }}>
                            {note?.pending ? "Revew and approve the note to save it in your client records" : "Note has been approved, to edit please uncheck the 'Approved' checkbox"}
                        </Alert>
                        <div className={noteDetailsStyle.intro_section}>
                            <div>
                                <div className={noteDetailsStyle.row}>
                                    <Typography variant="h4" fontWeight={"bolder"}>{(note?.name == "" || note?.name == null) ? "John Doe" : note?.name}</Typography>
                                    {note?.pending && <Edit onClick={() => {
                                        setOpenDialog(true)
                                        setDialogEditType("name")
                                    }} />}
                                </div >
                                <div className={noteDetailsStyle.row}>
                                    <Typography variant="body1" color={"grey"} >{(note?.email == "" || note?.email == null) ? "Add email or Add to existing client history" : note?.email}</Typography>
                                    {note?.pending && <Edit onClick={() => {
                                        setOpenDialog(true)
                                        setDialogEditType("email")
                                    }} />}
                                </div>
                                <br />
                                <Typography variant="body1" color={"grey"}>{moment(note?.timestamp).format("ddd, MMM Do hh:mm A")}</Typography>
                            </div>
                            {/* <div>
                                <Button variant="outlined"
                                    // onClick={() => setShowRecurringDialog(true)}
                                    disableElevation
                                    // disabled={!user.calenderConnected || !clientDetails.email}
                                    sx={{
                                        borderRadius: "20px"
                                    }}>Add To History</Button>
                            </div> */}
                        </div>
                        <br />
                        {/* <Typography variant="h5">Name</Typography> */}
                        <div>
                            <FormControlLabel control={<Checkbox onChange={toggleApprove} checked={!note?.pending} inputProps={{ 'aria-label': 'note status' }} size="large" />}
                                label={<Typography variant="h6" color="textSecondary" fontWeight={"bolder"}>Approved</Typography>} />
                            <Tooltip title={"Once you approve your note, it will get added to your client history"} arrow>
                                <InfoIcon />
                            </Tooltip>
                        </div>
                        <br />
                        <Divider variant="middle" component="div" sx={{ borderBottomWidth: 2, borderColor: "white" }} />
                        <br />
                        <Typography variant="h4" fontWeight={"bolder"}>Summary</Typography>
                        <div className={noteDetailsStyle.section_text}>
                            <Typography variant="body1">{note?.serene_notes.conversation_summary}</Typography>
                        </div>
                        <br />
                        <br />
                        <div className={noteDetailsStyle.notes_section}>
                            <div className={noteDetailsStyle.notes_section_heading}>
                                <Typography variant="h4" fontWeight={"bolder"}>Notes</Typography>
                                {note?.pending && <Edit onClick={() => {
                                    setOpenDialog(true)
                                    setDialogEditType(noteType == "BIRP" ? "birpNote" : "soapNote")
                                }} />}
                            </div>
                            <div onClick={() => setNoteType("SOAP")} className={noteType == "SOAP" ? noteDetailsStyle.note_type_selected : noteDetailsStyle.note_type}>SOAP</div>
                            <div onClick={() => setNoteType("BIRP")} className={noteType == "BIRP" ? noteDetailsStyle.note_type_selected : noteDetailsStyle.note_type}>BIRP</div>
                        </div>
                        {noteType == "SOAP" ? <SoapNotes soapNotes={note?.serene_notes.soap_report} /> : <BirpNotes birpNotes={note?.serene_notes.birp_notes} />}
                        <br />
                        <br />
                        <div>
                            <Typography variant="h4" fontWeight={"bolder"}>Beck Depression Inventory Scores</Typography>
                            <Typography variant="body1" color={"gray"}>Scores are automatically assigned based on the conversation</Typography>
                            <div className={noteDetailsStyle.section_text}>
                                <Typography variant="body2"><strong>Total</strong> {Object.values(note?.bdi_score ?? { total: 0 }).reduce((p, c) => p + c, 0)} ({label})</Typography>
                                <br />
                                <br />
                                <BdiChart data={note?.bdi_score} />
                            </div>
                        </div>
                        <br />
                        <br />
                        <div>
                            <Typography variant="h4" fontWeight={"bolder"}>Sentiment Analysis</Typography>
                            <Typography variant="body1" color={"gray"}>The sentiements expressed by the client during the session</Typography>
                            <div className={noteDetailsStyle.section_text}>
                                {typeof note?.serene_notes.sentiment === "string" ? note?.serene_notes.sentiment : ""}
                            </div>
                        </div>
                        <div className={noteDetailsStyle.footer} />
                    </div>
                </div>}
        </ProtectedRoute >);



}

