import React, { useState } from "react";
import { Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Divider, MenuItem, Select, TextField, FormControl } from "@mui/material";
import { useSelector } from "react-redux";
import { AppState, ClientDetails } from "../../types";

const EmailComponent = () => {
    const [newEmail, setNewEmail] = useState("");
    const [selectedClient, setSelectedClient] = useState("");
    const [selectedClientDetails, setSelectedClientDetails] = useState<ClientDetails | undefined>();
    const [searchTerm, setSearchTerm] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const userClients = useSelector((state: AppState) => state.clients);


    const filteredClients = userClients.clients.filter((client) =>
        client.client_name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSelectClient = (value: ClientDetails) => {
        setSelectedClient(value.client_name ?? "");
        setSelectedClientDetails(value)
        setNewEmail(value.email ?? "")
        setIsDropdownOpen(false);
    };

    return (
        <React.Fragment>
            <Typography
                variant="body1"
                color="grey"
                display="flex"
                alignItems="center"
            >
                <InfoIcon fontSize="small" sx={{ marginRight: 0.5 }} />
                Your client's email is used to organize notes and track their sessions
            </Typography>
            <TextField
                value={newEmail}
                onChange={(e) => {
                    setNewEmail(e.target.value)
                    setSelectedClient("")
                    setSelectedClientDetails(undefined)
                }}
                placeholder="Enter client email"
                variant="outlined"
                fullWidth
                sx={{ my: 2 }}
            />

            <Divider sx={{ display: "flex", alignItems: "center", my: 2 }}>
                <Typography variant="body2" color="grey">
                    OR
                </Typography>
            </Divider>

            <Typography
                variant="body1"
                color="grey"
                display="flex"
                alignItems="center"
            >
                <InfoIcon fontSize="small" sx={{ marginRight: 0.5 }} />
                Select an existing client
            </Typography>

            <FormControl fullWidth sx={{ my: 2 }}>
                <Select
                    value={selectedClient}
                    // onChange={(e) => handleSelectClient(e.target.value)}
                    open={isDropdownOpen}
                    onClose={() => setIsDropdownOpen(false)}
                    onOpen={() => setIsDropdownOpen(true)}
                    displayEmpty
                    renderValue={(value) => value || "Search or select an existing client"}
                >
                    <MenuItem disableGutters>
                        <TextField
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search clients"
                            variant="outlined"
                            fullWidth
                            size="small"
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => e.stopPropagation()}
                        />
                    </MenuItem>
                    {filteredClients.map((client) => (
                        <MenuItem
                            key={client.id}
                            value={client.client_name}
                            onClick={() => handleSelectClient(client)}
                        >
                            <Typography variant="body1">
                                {client.client_name}
                            </Typography>
                            <Typography variant="body2" color="grey" sx={{ ml: 1 }}>
                                ({client.email})
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </React.Fragment>
    );
};

export default EmailComponent;
