import { createSlice } from '@reduxjs/toolkit'
import { BookingData } from '../types';

/**
 * Calender data
 */
export const calenderSlice = createSlice({
    name: 'calender',
    initialState: {
        fetched: false,
        events: [] as BookingData[],
    },
    reducers: {
        updateCalenderData: (state, action) => {
            state.fetched = true;
            state.events = filterFutureEvents(action.payload ?? [])
        },
    },
})

const filterFutureEvents = (events: BookingData[]): BookingData[] => {
    console.log(events)
    if (events?.filter) {
        const now = new Date().toISOString();
        return events?.filter(event => new Date(event.start.dateTime) > new Date(now));
    } else {
        return []
    }
};

// Action creators are generated for each case reducer function
export const { updateCalenderData } = calenderSlice.actions

export default calenderSlice.reducer

